import { GetBy } from '../core/Element';
import { Functions } from '../utils/Functions';
import ImageObject from '../display/ImageObject';
import VideoObject from '../display/VideoObject';
import { Videos } from './Videos';

export class LazyImages {
  static _images = {};
  static _observer = null;
  static _options = {
    rootMargin: '1000px 1000px 1000px 1000px',
    threshold: 0
  };

  static _worker = new Worker('/imageLoaderWorker.js');

  static init() {
    this.setupObserver();
    this.getImages();
    this.initWorker();
  };

  static getImages() {
    requestAnimationFrame(() => {
      [...GetBy.selector("[data-item-load]")].map(dom => {
        const id = Functions.getId(dom);
        let item;

        switch (dom.tagName.toUpperCase()) {
          case 'IMG':
            item = new ImageObject(dom);
            break;

          case 'VIDEO':
            item = new VideoObject(dom);
            break;
        }

        if (item) {
          this._images[id] = item;
          this._observer.observe(dom);
        }
      });
    });
  };

  static setupObserver() {
    if (this._observer) {
      this._observer.disconnect();
    }

    this._observer = new IntersectionObserver((entries) => { 
      requestAnimationFrame(() => this.checkObserver(entries));
    }, this._options);
  }

  static checkObserver(entries) {
    entries.forEach(entry => {
      const id = Functions.getId(entry.target);
      const image = this._images[id];

      if (entry.isIntersecting) {
        this.loadImageInWorker(image, entry.target);
        this._observer.unobserve(entry.target);
      }
    });
  }

  static loadImageInWorker(image, domElement) {
    this._worker.postMessage({
      id: Functions.getId(domElement),
      src: image.src
    });
  }

  static initWorker() {
    this._worker.onmessage = (event) => {
      this.onWorkerMessage(event);
    };

    this._worker.onerror = (error) => {
      console.error('Worker error:', error);
    };
  }

  static onWorkerMessage(event) {
    const { id, src, status } = event.data;

    if (status === 'loaded') {
      const mediaElement = this._images[id];
      if (mediaElement) {
        requestAnimationFrame(() => {
          if(mediaElement.item.tagName.toUpperCase()!="VIDEO") {
            mediaElement.item.setAttribute("src", src);
          } else {
            mediaElement.item.setAttribute("poster", src);
          }
        });
      }
    } else if (status === 'error') {
      console.error(`Error loading image with src: ${src}`);
    }
  }

  static dispose() {
    if (this._observer) { this._observer.disconnect(); }

    Object.entries(this._images).map((item) => { item[1].dispose() });

    this._images = {};
    this._observer = null;

    if (this._worker) {
      this._worker.terminate();
      this._worker = null;
    }
  }
}
