import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__Text extends VScroll_Item {
  text;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);
    this.text = GetBy.class('__text', __link)[0];
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  drawHook() {
    const normalize = this.progress < .5 ? Math.max(0, Maths.normalize(.35, .25, this.progress)) : 1;
    this.text.style.opacity = normalize;
  }
}

Scroll._registerClass('BlockText', ScrollItem__Text);
