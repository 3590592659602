import FluidLink from '../_app/cuchillo/components/FluidLink';
import { Basics, isTouch } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { CursorTypes } from '../_app/cuchillo/cursor/CursorTypes';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class FluidCursor {

  static button;
  static x = 0;
  static y = 0;
  static isShow = false;
  static easing = 0.1;
  static timer = null;
  static items = [];

  static init() {
    if (Basics.isReducedMotion || isTouch) return;
    FluidCursor.button = new FluidLink(GetBy.id("FluidCursor"), true);
    FluidCursor.reset();
  }

  static start() {
    if(isTouch) return;
    
    if (!FluidCursor._isMoveCancel) {
      FluidCursor._isEnabledMove = true;
    }
    FluidCursor.reset();
  }

  static reset() {
    if(isTouch) return;
    
    if (Basics.isReducedMotion) return;
    FluidCursor.isEnabled = true;

    if (!Basics.isTouch) {
      FluidCursor.dispose();
      FluidCursor.doCursor("[data-fluid-cursor]");
    }
  }

  static doCursor(__type) {
    if(isTouch) return;
    
    FluidCursor.items = [...GetBy.selector(__type)];

    for (let i = 0, j = FluidCursor.items.length; i < j; i++) {
      let item = FluidCursor.items[i];

      item.removeAttribute(__type);
      item.addEventListener(Basics.mouseOver, FluidCursor._hoverCursor);
      item.addEventListener(Basics.mouseOut, FluidCursor._outCursor);
    }
  }

  static _hoverCursor(e) {
    if(isTouch) return;

    let item = e.currentTarget;
    FluidCursor.show();
    FluidCursor.button.change(item.dataset.text);
    if (item.dataset.icon) {
      FluidCursor.button.changeIcon(item.dataset.icon);
    }
  }

  static _outCursor() {
    if(isTouch) return;

    FluidCursor.hide();
  }

  static dispose(__type) {
    if(isTouch) return;

    for (let i = 0, j = FluidCursor.items.length; i < j; i++) {
      let item = FluidCursor.items[i];
      item.removeEventListener(Basics.mouseOver, FluidCursor._hoverCursor);
      item.removeEventListener(Basics.mouseOut, FluidCursor._outCursor);
    }
    FluidCursor.items = [];
  }

  static show() {
    if(isTouch) return;

    clearTimeout(FluidCursor.timer);
    if (FluidCursor.isShow) return;
    FluidCursor.timer = null;
    FluidCursor.isShow = true;
    FluidCursor.button.show(0);
  }

  static hide() {
    if(isTouch) return;
    if (!FluidCursor.isShow) return;

    clearTimeout(FluidCursor.timer);
    FluidCursor.timer = setTimeout(() => {
        FluidCursor.isShow = false;
        FluidCursor.button.hide(.2);
    }, 100);
  }

  static loop() {
    if(isTouch) return;
    FluidCursor.x = Maths.precission(FluidCursor.x + (Interaction.positions.mouse.x - FluidCursor.x) * FluidCursor.easing);
    FluidCursor.y = Maths.precission(FluidCursor.y + (Interaction.positions.mouse.y - FluidCursor.y) * FluidCursor.easing);
    FluidCursor.button.container.style[CSS.transform] = CSS.translate3D(FluidCursor.x, FluidCursor.y, 15);
  }

  static resize() {
    if(isTouch) return;
    FluidCursor.button.resize();
  }
}
