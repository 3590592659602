import gsap, { Power3, Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Ease } from '../_app/cuchillo/utils/Ease';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import Header from '../layout/Header';
import { isMobile } from '../_app/cuchillo/core/Basics';

class ScrollItem__BillboardProject extends VScroll_Item {
  _bg;
  _title;
  _info;
  _infoImg;
  _isShowInfo = true;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._call = () => this.loop();

    this._info = GetBy.class('info', __link)[0];
    gsap.set(this._info, { opacity: 0 });

    this._infoImg = GetBy.selector('figure', this._info)[0];

    this._bg = GetBy.selector('figure', __link)[0];

    this._title = new SplitText(GetBy.class('_title', __link)[0], {
      type: 'chars',
    }).chars;
    gsap.set(this._title, { y: '100%' });

    this.onShow = () => {
      //Header.forceWhite(true);

      let delay = 0;

      for (let i = 0; i < this._title.length; i++) {
        const item = this._title[i];
        gsap.to(item, {
          y: 0,
          duration: 1 + i * 0.1,
          delay: delay + i * 0.01,
          ease: Ease.EASE_CUCHILLO_OUT
        });
      }

      delay += .4;
      gsap.to(this._info, {
        opacity: 1,
        duration: .3,
        delay,
        ease: Power2.easeOut
      });
    };
    this.onHide = () => {
      //Header.forceWhite(false);
    };
    this.onMove = () => {
      this.loop();
    };
  }

  loop() {
    const y = Math.max(Maths.map(this.progress, .5, 1, 0, 25), 0);
    const scale = Math.max(Maths.map(this.progress, .5, 1, 1, 1.4), 1);
    const scaleSm = Math.max(Maths.map(this.progress, .5, 1, 1, 1.2), 1);

    gsap.set(this._bg, { y: `${y}%`, scale });
    gsap.set(this._infoImg, { scale: scaleSm });

    if (this.realY < -this.heightCheck) {
      this.hideInfo();
    } else {
      this.showInfo();
    }
  }

  showInfo() {
    if (this._isShowInfo || isMobile) return;
    this._isShowInfo = true;
    gsap.killTweensOf(this._info);
    gsap.to(this._info, {
      opacity: 1,
      duration: .2,
      ease: Power2.easeOut
    });
  }

  hideInfo() {
    if (!this._isShowInfo || isMobile) return;

    this._isShowInfo = false;
    gsap.killTweensOf(this._info);
    gsap.to(this._info, {
      opacity: 0,
      duration: .2,
      ease: Power2.easeOut
    });
  }

  dispose() {
    super.dispose();
    //Header.forceWhite(false);
  }

  resize(__w, __h) {
    super.resize(__w, __h);
    this.heightCheck = Metrics.HEIGHT - this._info.offsetHeight * 1.1;
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('BillboardProject', ScrollItem__BillboardProject);
