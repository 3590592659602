import gsap, { Expo, Power2, Power4 } from 'gsap';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__BillboardHome extends VScroll_Item {
  _caption;
  _text;
  _central
  _image;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);
    this._caption = GetBy.class('__caption', __link)[0];
    this._text = GetBy.class('left', this._caption)[0];
    this._central = GetBy.class('__central', __link)[0];
    this._image = GetBy.selector('figure', this._central)[0];
    

    this.onShow = () => {
      gsap.to(this._central, {y:0, duration:1.2, ease:Expo.easeOut, delay:0, force3D:true});
      gsap.to(this._image, {scaleX:1, scaleY:1, duration:1.8, ease:Expo.easeOut, delay:0, force3D:true});
      gsap.to(this._text, {opacity:1, duration:.4, ease:Power2.easeOut, delay:.7, force3D:true});
    };
  }

  drawHook() {
    const normalize = Math.max(0, Maths.normalize(.5, .6, this.progress));
    this._caption.style.opacity = normalize;
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('BillboardHome', ScrollItem__BillboardHome);
