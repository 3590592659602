import FluidNavLink from '../_app/cuchillo/components/FluidNavLink';
import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import InfiniteScroll__Item from '../_app/cuchillo/scroll/InfiniteScroll__Item';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';
import Header from '../layout/Header';
import Default from './Default';

export default class Services extends Default {
  
  domIndice;
  footer;

  constructor() {
    super();

    this.domIndice = GetBy.class("block-billboard-index__info", this.container)[0];
    
  }

  beforeShow() {
    super.beforeShow();
    Header.mode = Header.HEADER_MODE_DEFAULT;
    Header.enableBlurMode(false);
    this.footer = Scroll.engine._items[Scroll.engine.total_items - 1];
  }
  
  afterHide() {
    super.afterHide();
  }

  resize() {
    super.resize();
  }

  loop() {
    if(!isSmartphone) this.domIndice.style.transform = CSS.translate3D(0, Scroll.y, 10);
  }
}


ControllerPage._addPage('services', Services);
