import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Ease } from '../_app/cuchillo/utils/Ease';
import { ScrollItem__BlockLines } from './ScrollItem__BlockLines';
import { Functions } from '../_app/cuchillo/utils/Functions';

class ScrollItem__BlockTitleGallery extends ScrollItem__BlockLines {
  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._images = GetBy.class('__img', __link);
    this.setupItems();
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  setupItems() {
    let sizes = Functions.arrayRandom(['--size-1', '--size-2', '--size-3']);
    let positions = Functions.arrayRandom(['--pos-1', '--pos-2', '--pos-3',]);
    let offsets = Functions.arrayRandom(['--offset-1', '--offset-2', '--offset-3']);

    for (let i = 0; i < this._images.length; i++) {
      const item = this._images[i];
      item.classList.add(positions[i]);
      item.classList.add(sizes[i]);
      item.classList.add(offsets[i]);
    }
  }
}

Scroll._registerClass('BlockTitleGallery', ScrollItem__BlockTitleGallery);
