import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__HighlightText extends VScroll_Item {
  _caption;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);
    this.bg = GetBy.class('__bg', __link)[0];
    this.text = GetBy.class('__text', __link)[0];
    this.items = [...GetBy.selector(".__text > div > span", __link)];
    this.totalItems = this.items.length;
    this.inc = 1 / this.totalItems;
  }

  drawHook() {
    this.animationVideo();
    this.animationText()
  }

  animationVideo() {
    const alpha = this.progress < .5 ? Maths.normalize(0.2, 0, this.progress) : Maths.normalize(0.8, 1, this.progress);
    this.bg.style.opacity = alpha;
  }

  animationText() {
    //GENERAL
    const alpha = this.progress < .5 ? Maths.normalize(0.15, .1, this.progress) : Maths.normalize(0.75, 0.8, this.progress);
    this.text.style.opacity = alpha;

    //SPANS
    const progress = this.progress;
    const norm = Maths.precission(Maths.normalize(.6, .1, progress));

    let p0 = 0;
    let p1 = this.inc;

    for (let i = 0; i < this.totalItems; i++) {
      const o = Math.max(.2, Maths.normalize(p1, p0, norm));

      this.items[i].style.opacity = o;



      p0 = p1;
      p1 = p1 + this.inc;
    }
  }


  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('HighlightText', ScrollItem__HighlightText);
