import BezierEasing from 'bezier-easing';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderHorizontalScroll } from '../_app/cuchillo/components/SliderHorizontalScroll';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Metrics } from "../_app/cuchillo/core/Metrics";
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { CSS } from '../_app/cuchillo/utils/CSS';
import FluidNavLink from '../_app/cuchillo/components/FluidNavLink';

class ScrollItem__HighlightProjects extends VScroll_Item {
  _slider;
  _limit;
  static _title;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    ScrollItem__HighlightProjects._title = new FluidNavLink(GetBy.class("__title", this.item)[0], false, .1)

    this._slider = new SliderHorizontalScroll(__link,
      {
        interaction: false,
        itemClass: SliderScroll__Horizontal__Item
      });

    this._limit = 1 / this._slider.items.length;

    this.onShow = () => {
      ScrollItem__HighlightProjects._title.show();
      this.item.style.opacity = 0;
    };
    this.onHide = () => {
      ScrollItem__HighlightProjects._title.hide();
      this._slider.progress = Math.round(this.progress);
      this.item.style.opacity = 0;
    };
    this.onMove = () => {
      //const p = Maths.map(1 - this.progressInside, 0, 1, this._limit, 1 - this._limit);
      this._slider.progress = this.progress;

      this.item.style.opacity = Maths.normalize(.85, .95, this.progress)

      //console.log(this._slider.progress)
      this._slider.loop();
    }
  }

  drawHook() {
    ScrollItem__HighlightProjects._title.loop();
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  dispose() {
    this._slider.dispose();
    super.dispose();
  }

  resize(__w, __h) {
    super.resize(__w, __h)
  }
}

Scroll._registerClass('HighlightProjects', ScrollItem__HighlightProjects);

class SliderScroll__Horizontal__Item extends VScroll_Item {
  figure;
  image;
  cover;
  coverImage;
  easing = BezierEasing(0.69, 0.27, 0.69, 0.28);
  hasHiddenEnabled = false;
  distance;
  offset;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    if (this.item.dataset.id) {
      this.figure = GetBy.id(this.item.dataset.id);
      this.image = GetBy.selector("img", this.figure)[0];
      this.cover = GetBy.class("__" + this.item.dataset.id)[0];
      this.coverImage = GetBy.selector("img", this.cover)[0];
      this.distance = Metrics.HEIGHT;// + this.figure.offsetHeight;
      this.offsetfigure = this.figure.offsetHeight * .5;

      this.cover.style.opacity = 0;

      this.onShow = () => { };
      this.onHide = () => { };
    }
    this.onMove = () => {
      this.loop();
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  mouseOver() { }
  mouseDown() { }
  mouseUp() { }

  loop() {
    if (!this.item.dataset.id) {
      if (this.progress > .4 && this.progress < .6) ScrollItem__HighlightProjects._title.change(" ", null, null, true);
    } else {

      let pScale = this.progress < .5 ? Math.max(0, Maths.normalize(0, .5, this.progress)) : Math.max(0, Maths.normalize(1, .5, this.progress));


      const pY = (this.distance - (this.distance * this.progress)) - this.offsetfigure;

      const p = 1 - this.progress;
      const scale = Math.max(Maths.lerp(1, .9, this.easing(Math.abs(pScale))), 0);
      const scale2 = Math.max(Maths.lerp(1.5, 1, this.easing(Math.abs(pScale))), 0);
      const scaleCover = Math.max(Maths.lerp(1, 1.2, this.easing(Math.abs(this.progress))), 0);
      const origin = Maths.lerp(-10, 110, this.progress);



      const alpha = Maths.normalize(.2, 0, this.progress);

      this.figure.style.transform = CSS.translate3D(0, pY, 0) + " " + CSS.scale(scale);
      this.figure.style.transformOrigin = `50% ${origin}% `;

      this.image.style.transform = CSS.scale(scale2);
      this.image.style.transformOrigin = `50% ${origin}% `;

      //this.coverImage.style.transform = CSS.scale(scaleCover);

      this.figure.setAttribute("data-p", this.progress)

      if (this.progress > .4 && this.progress < .6) {

        ScrollItem__HighlightProjects._title.change(this.item.dataset.title, null, null, true);
      } else {
        //this.cover.style.opacity = 0;
      }

      this.cover.style.opacity = alpha;
    }
  }

  show() {
    super.show();
  }

  resize() {
    super.resize();
    if (!this.item.dataset.id) return;
    this.distance = Metrics.HEIGHT;// + this.figure.offsetHeight;
    this.offsetfigure = this.figure.offsetHeight * .5;
  }

  hide() {
    super.hide();
  }
}
