import FluidNavLink from '../_app/cuchillo/components/FluidNavLink';
import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import InfiniteScroll__Item from '../_app/cuchillo/scroll/InfiniteScroll__Item';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';
import Header from '../layout/Header';
import Sidemenu from '../layout/Sidemenu';
import NoScroll from './NoScroll';

export default class Projects extends NoScroll {
  scroller;
  sliderDom = GetBy.class("widget-projects", this.container)[0];
  static _title;
  static _counter;
  static _current;
  static _total;

  nextButton;
  prevButton;

  _calls = {
    next: ()=> { this.scroller.goto(Projects._current == Projects._total - 1? 0 : Projects._current + 1); },
    prev: ()=> { this.scroller.goto(Projects._current == 0? Projects._total - 1 : Projects._current - 1); },
  }

  static set counter(__n) {
    Projects._current = __n - 1;
    Projects._counter.textContent = __n.toString().padStart(2, '0');
  }


  constructor() {
    super();

    Projects._title = new FluidNavLink(GetBy.class("__title", this.item)[0], false, .1);
    Projects._counter = GetBy.class("__counter", this.container)[0];
    this.nextButton = GetBy.class("__next", this.container)[0];
    this.prevButton = GetBy.class("__prev", this.container)[0];

    
    this.scroller = new InfiniteScroll(isSmartphone? InfiniteScroll.AXIS_Y : InfiniteScroll.AXIS_X, {
      domResize: this.sliderDom,
      container: this.sliderDom,
      multiplicator: 2,
      itemClass: Projects__Item,
      minSpeed: 0,
      inverted: false,
      gap: 0,
      hasVirtuaScroll: true
    }); 
    
    Projects._total = this.scroller.total_items;

    this.nextButton.addEventListener(Basics.clickEvent, this._calls.next);
    this.prevButton.addEventListener(Basics.clickEvent, this._calls.prev);
  }

  beforeShow() {
    super.beforeShow();
    Header.mode = Header.HEADER_MODE_DEFAULT;
    
    this.scroller.start();
    this.scroller.show();
    Projects._title.show();

    
  }

  
  afterHide() {
    super.afterHide();
    this.nextButton.removeEventListener(Basics.clickEvent, this._calls.next);
    this.prevButton.removeEventListener(Basics.clickEvent, this._calls.prev);
    this.scroller.hide();
    this.scroller.dispose();
  }

  resize() {
    super.resize();
    this.scroller.resize();
    Projects._title.resize();
  }

  loop() {
    this.scroller.loop();
    Projects._title.loop();
  }
}

class Projects__Item extends InfiniteScroll__Item {
  image;
  cover;
  coverImage;
  
  constructor(__d, __i, __axis = InfiniteScroll.AXIS_Y) {
      super(__d, __i, __axis, true);
      this.image = GetBy.selector("img", this.dom)[0];
      this.cover = GetBy.class("__" + __d.dataset.id)[0];
      this.coverImage = GetBy.selector("img", this.cover)[0];
  }

  drawHook() {
     this.move();
  }

  updateImage() {
    console.log(this.image.src)
    this.coverImage.src = this.image.src;
    
  }

  move() {
      const scale = Math.max(1, Maths.lerp(1.9, 1.5, this.progressInside));
      const scale2 = Math.max(1, Maths.lerp(1.2, 1.1, this.progressInside));

      
      this.image.style.transform = CSS.translate3D(this.realX * -.8,0,0) + " " + CSS.scale(scale)
      this.image.style.opacity = 3 - this.progressInside;
 
      ///let alpha = Maths.normalize(1.5,0.5,this.progressInside);
      let alpha = Maths.normalize(.5,0,this.progressInside);
      if(this.progressInside < .5) {
        alpha = Maths.normalize(.1,0,this.progressInside);
      } else {
        alpha = Maths.normalize(1,1.1,this.progressInside);
      }




      if(alpha > .5) {
        this.cover.style.pointerEvents = "all";
        Projects._title.change(this.dom.dataset.title, null, null, true);
        Projects.counter = this.indice+1;
      } else {
        this.cover.style.pointerEvents = "none";
      }

      this.cover.style.opacity = alpha;
      this.cover.style.transform = CSS.scale(scale2)

    

     /* if(this.progressInside > -.5 && this.progressInside < .5) this.cover.style.opacity = this.progressInside;
      else this.cover.style.opacity = 0;*/

      
  }
}


ControllerPage._addPage('projects', Projects);
