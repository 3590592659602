import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { isMobile } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import Header from '../layout/Header';
import NoScroll from './NoScroll';
import BezierEasing from 'bezier-easing';

export default class Gallery extends NoScroll {
  _slider;

  constructor() {
    super();

    if (!isMobile) {
      this._slider = new SliderScroll(this.container, {
        interaction: true,
        hasScrollbar: false,
        itemClass: ScrollItem__SliderImages__Item
      });
    }
  }

  beforeShow() {
    super.beforeShow();
    Header.hideAlpha();
    Header.mode = Header.HEADER_MODE_DEFAULT;
  }

  resize(w, h) {
    super.resize();
    if (this._slider) this._slider.resize();
  }

  loop() {
    if (this._slider) this._slider.loop();
  }

  afterHide() {
    super.afterHide();
    if (this._slider) this._slider.dispose();
    Header.showAlpha();
  }
}

class ScrollItem__SliderImages__Item extends VScroll_Item {
  easing = BezierEasing(0.69, 0.27, 0.69, 0.28);
  hasHiddenEnabled = false;
  image;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.image = GetBy.selector('img', __link)[0];

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => {
      this.loop();
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  loop() {
    if (!this.image) return;

    const p = this.progress - .5;
    const scale = Math.max(Maths.lerp(1, 3, this.easing(Math.abs(p))), 1);
    const origin = Maths.clamp(Maths.lerp(50, 100, p), 5, 95);

    this.image.style.transform = `scale3D(${scale}, ${scale}, ${scale})`;
    this.image.style.transformOrigin = `${origin}% 50%`;
  }

  //   mouseOver () { }
  //   mouseDown () { }
  //   mouseUp () { }

  //   show () {
  //     super.show();
  //   }

  //   hide () {
  //     super.hide();
  //   }
}


ControllerPage._addPage('gallery', Gallery);
