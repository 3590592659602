import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Ease } from '../_app/cuchillo/utils/Ease';
import { Basics } from '../_app/cuchillo/core/Basics';

export class ScrollItem__BlockLines extends VScroll_Item {
  _title;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    let title = GetBy.selector('.__title p', __link);
    if (!title) title = GetBy.class('__title', __link)[0];

    this._title = new SplitText(title, {
      type: 'lines',
      linesClass: 'line-children'
    }).lines;
    new SplitText(title, {
      type: 'lines',
      linesClass: 'line-parent'
    });
    if (this._title) gsap.set(this._title, { y: '100%' });

    this.onShow = () => {
      for (let i = 0; i < this._title.length; i++) {
        const item = this._title[i];
        gsap.to(item, {
          y: 0,
          duration: 1.2,
          delay: i * 0.15,
          ease: Ease.EASE_CUCHILLO_OUT
        });
      }
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('BlockLines', ScrollItem__BlockLines);
