import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Ease } from '../_app/cuchillo/utils/Ease';

class ScrollItem__TextStepsImage extends VScroll_Item {
  _slides = [];
  _bg;
  _bgImg;
  _target;
  _title;

  _call = {
    loop: () => this.loop()
  }

  _positions = {
    visor: {
      x: 0,
      y: 0
    },
    bg: {
      x: 0,
      y: 0
    }
  }

  _sizes = {
    x: 0,
    y: 0
  }

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._bg = GetBy.selector('.__bg', __link)[0];
    this._bgImg = GetBy.selector('.__bg img', __link)[0];

    this._target = GetBy.selector('.__img', __link)[0];

    [...GetBy.class('__slide', __link)].map((item, i) => {
      this._slides.push(new Steps__Item(item, i));
    });

    let title = GetBy.selector('.__title p', __link)[0];
    if (!title) title = GetBy.class('__title', __link)[0];

    new SplitText(title, {
      type: 'words',
      wordsClass: 'line-parent'
    });
    this._title = new SplitText(title, {
      type: 'words',
      wordsClass: 'line-children'
    }).words;
    gsap.set(this._title, { y: '100%' });

    this.onShow = () => {
      for (let i = 0; i < this._title.length; i++) {
        const item = this._title[i];
        gsap.to(item, {
          y: 0,
          duration: 1.2,
          delay: i * 0.2,
          ease: Ease.EASE_CUCHILLO_OUT
        });
      }

      gsap.ticker.add(this._call.loop);
    };

    this.onHide = () => {
      gsap.ticker.remove(this._call.loop);
    };
  }

  drawHook() {
    let active = 0;
    const center = Metrics.HEIGHT / 2;

    this._slides.forEach((slide, i) => {
      const isVisible = (slide.top >= 0 && slide.bottom <= center || slide.top < center && slide.bottom > 0);
      if (isVisible) active = i;
    });

    this._slides.forEach((slide, i) => {
      if (i === active) slide.show();
      else slide.hide();
    });
  }

  loop() {
    const x = Maths.clamp((Interaction.positions.mouse.x / Metrics.WIDTH - .5) * 10, -10, 10);
    const y = Maths.clamp((Interaction.positions.mouse.y / Metrics.HEIGHT - .5) * 10, -10, 10);

    this._positions.visor.x = Maths.lerp(this._positions.visor.x, x * .25, .06);
    this._positions.visor.y = Maths.lerp(this._positions.visor.y, y * .25, .06);
    gsap.set(this._target, { x: `${this._positions.visor.x}%`, y: `${this._positions.visor.y}%` });

    this._positions.bg.x = Maths.lerp(this._positions.bg.x, x * .6, .025);
    this._positions.bg.y = Maths.lerp(this._positions.bg.y, y * .6, .025);
    gsap.set(this._bg, { x: `${this._positions.bg.x}%`, y: `${this._positions.bg.y}%` });
  }

  resize(w, h) {
    super.resize(w, h);

    this._slides.forEach(slide => slide.resize());
  }
}

Scroll._registerClass('TextStepsImage', ScrollItem__TextStepsImage);

class Steps__Item {
  dom;
  index;
  activeClass = '--active';
  sizes = {
    top: 0,
    bottom: 0,
    left: 0,
    height: 0,
    width: 0,
    x: 0,
    y: 0
  };
  isActive = false;

  number;
  span;
  newSpan;
  _height = 0;
  _nTitles = 0;
  _topHolder = 0;
  _topHolderTarget = 0;

  get top() {
    return this.sizes.top + Scroll.y;
  }

  get bottom() {
    return this.sizes.bottom + Scroll.y;
  }

  // get topHolder() { return this._topHolder; }
  // set topHolder(__n) {
  //   this._topHolder = __n;
  //   this.number.style.transform = `translate3D(0, ${this._topHolder}px, 0)`;
  // }

  constructor(__dom, __num, __i) {
    this.dom = __dom;
    this.index = __i + 1;

    // this.number = GetBy.selector('span', __num)[0];
    // this.span = GetBy.selector('span', __num)[1];
  }

  show() {
    if (!this.isActive) {
      this.isActive = true;
      this.dom.classList.add(this.activeClass);

      // this.showNumber('--active', .1);
    }
  }

  hide() {
    if (this.isActive) {
      this.isActive = false;
      this.dom.classList.remove(this.activeClass);

      // this.showNumber();
    }
  }

  // showNumber(__c = undefined, __d = 0) {
  //   this.newSpan = document.createElement('span');
  //   this.newSpan.textContent = this.index;

  //   if (__c) this.newSpan.classList.add(__c);

  //   gsap.killTweensOf(this.span);
  //   gsap.to(this.span, { opacity: 0, duration: .2, delay: __d, ease: Power2.easeIn });

  //   this.span = this.newSpan;
  //   this.number.appendChild(this.newSpan);

  //   this._nTitles++;
  //   gsap.delayedCall(__d, () => {
  //     this._topHolderTarget = -this._height * this._nTitles;
  //   });
  // }

  // loop() {
  //   this.speedTopHolder = (this._topHolderTarget - this._topHolder) * .2;
  //   this.topHolder = this._topHolder + this.speedTopHolder;
  // }

  resize() {
    this.sizes = this.dom.getBoundingClientRect();

    // this._height = this.span.getBoundingClientRect().height;

    // this.topHolder =
    //   this._topHolderTarget = -this._height * this._nTitles;
  }
}

// class NumbersHolder {
//   dom;
//   child;

//   height;
//   heightChild;

//   constructor(__dom) {
//     this.dom = __dom;
//     this.child = GetBy.class('__holder', __dom)[0];
//   }

//   loop(p) {
//     const y = Maths.map(p, 0, 1, 0, this.height - this.heightChild);
//     gsap.set(this.child, { y });
//   }

//   resize() {
//     this.height = this.dom.getBoundingClientRect().height;
//     this.heightChild = this.child.getBoundingClientRect().height;
//   }
// }
