import BezierEasing from 'bezier-easing';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderHorizontalScroll } from '../_app/cuchillo/components/SliderHorizontalScroll';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Metrics } from "assets/scripts/_app/cuchillo/core/Metrics";
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { isMobile } from '../_app/cuchillo/core/Basics';

class ScrollItem__SliderScrollHorizontal extends VScroll_Item {
  _slider;
  _limit;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    if (!isMobile) {
      this._slider = new SliderHorizontalScroll(__link,
        {
          interaction: false,
          hasLimits: false,
          itemClass: SliderScroll__Horizontal__Item
        });

      this._limit = 1 / this._slider.items.length;
    }

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => {
      if (isMobile) return;

      //const p = Maths.map(1 - this.progress, 0, 1, this._limit, 1 - this._limit);
      this._slider.progress = this.progress;
      this._slider.loop();
    }
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================

  dispose() {
    if (this._slider) this._slider.dispose();
    super.dispose();
  }

  resize(__w, __h) {
    if (this._slider) this._slider.resize();
    super.resize(__w, __h)
  }
}

Scroll._registerClass('slider-horizontal-scroll', ScrollItem__SliderScrollHorizontal);

class SliderScroll__Horizontal__Item extends VScroll_Item {
  image;
  easing = BezierEasing(0.69, 0.27, 0.69, 0.28);
  hasHiddenEnabled = false;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.image = GetBy.selector('img', __link)[0];

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => {
      this.loop();
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  // mouseOver() { }
  // mouseDown() { }
  // mouseUp() { }

  loop() {
    if (!this.image) return;

    const p = 1 - this.progressInside;
    const scale = Math.max(Maths.lerp(1, 1.08, this.easing(Math.abs(p))), 1);
    const origin = Maths.clamp(Maths.lerp(50, 60, p), 5, 95);

    this.image.style.transform = `scale3D(${scale}, ${scale}, ${scale})`;
    this.image.style.transformOrigin = `${origin}% 50%`;
  }

  // show() {
  //   super.show();
  // }

  // hide() {
  //   super.hide();
  // }
}
