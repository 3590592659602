import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { GetBy } from '../_app/cuchillo/core/Element';

class ScrollItem__SliderDefault extends VScroll_Item {
    _call;
    _slider;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._slider = new SliderScroll(__link, {
            interaction: true,
            hasScrollbar: false,
            itemClass: ScrollItem__SliderImages__Item
        });

        this._call = () => this.loop();
        this.onShow = () => {
            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            gsap.ticker.remove(this._call);
        };
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._slider) this._slider.resize();
    }

    loop() {
        if (this._slider) this._slider.loop();
    }

    dispose() {
        if (this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__SliderImages__Item extends VScroll_Item {
    hasHiddenEnabled = false;
    // info;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this.image = GetBy.selector('img', __link)[0];
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    update() {
        let blur = 0;

        if (this.progress >= .55) {
            blur = Math.floor(Maths.map(this.progress, .55, 1, 0, 20));
        } else if (this.progress <= .45) {
            blur = Math.floor(Maths.map(this.progress, 0, .45, 20, 0));
        }

        this.image.style.filter = `blur(${blur}px)`;

        super.update();
    }

    //   mouseOver () { }
    //   mouseDown () { }
    //   mouseUp () { }

    //   show () {
    //     super.show();
    //   }

    //   hide () {
    //     super.hide();
    //   }
}

Scroll._registerClass('SliderDefault', ScrollItem__SliderDefault);
