import { GetBy } from '../_app/cuchillo/core/Element';
import _Sidemenu from '../_app/cuchillo/layout/Sidemenu';
import { FluidNavLinkGroup } from '../_app/cuchillo/components/FluidNavLink';
import { FluidLinkGroup } from '../_app/cuchillo/components/FluidLink';
import gsap, { Expo, Power2, Power4 } from 'gsap';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import InfiniteScroll from '../_app/cuchillo/scroll/InfiniteScroll';
import InfiniteScroll__Item from '../_app/cuchillo/scroll/InfiniteScroll__Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import BezierEasing from 'bezier-easing'

export default class Sidemenu extends _Sidemenu {
  static links = new FluidNavLinkGroup();
  static links2 = new FluidLinkGroup();

  static fake_bg = GetBy.selector("#Sidemenu-fake")[0];
  static bg = GetBy.selector("#Sidemenu > .bg")[0];
  static holder = GetBy.selector("#Sidemenu > .content")[0];
  static content = GetBy.selector("#Sidemenu > .content > div")[0];
  static sliderDom = GetBy.selector("#Sidemenu .sidemenu__slider")[0];
  static easing = BezierEasing(0.69, 0.27, 0.69, 0.28);
  static size = {
    width: 0,
    height: 0
  }

  static init () {
    this.resize(true);
    gsap.set(this.holder, { height: 0 });
    gsap.set(this.sliderDom, { scaleX: 10, scaleY:10, force3D: true });
    gsap.set(this.bg, { scaleY: 0, force3D: true });
    gsap.set(this.fake_bg, { opacity: 0 });

    this.scroller = new InfiniteScroll(isSmartphone? InfiniteScroll.AXIS_X : InfiniteScroll.AXIS_Y, {
      domResize: this.sliderDom,
      container: this.sliderDom,
      multiplicator: 2,
      itemClass: SidemenuSlider__Item,
      minSpeed: .5,
      inverted: false,
      gap: 0,
      hasVirtuaScroll: true
    });    
  }

  static show__effect() {
    this.scroller.start();
    this.scroller.show();
    this.scroller.options.minSpeed = 40;

    gsap.to(this.fake_bg, { opacity:1, duration:.6, ease: Power2.easeOut });
    gsap.to(this.bg, { scaleY:1, duration:1, ease: Power4.easeInOut, force3D: true, delay:.0 });
    gsap.to(this.holder, { height:this.size.height, duration:1, ease: Power4.easeInOut, delay:.0 });
    gsap.to(this.sliderDom, { scale:1, duration:1.5, ease: Expo.easeOut, force3D: true, delay:0 });
    gsap.to(this.scroller.options, { minSpeed:.5, duration:2, ease: Power2.easeInOut, delay:0 });
    this.links.show(.2);
  }

  static hide__effect() {
    this.links.hide();
    gsap.to(this.content, { opacity:0, duration:.3, ease: Power2.easeIn });
    gsap.to(this.scroller.options, { minSpeed:80, duration:.4, ease: Power2.easeOut, delay:0 });
    gsap.to(this.fake_bg, { opacity:0, duration:.3, delay:.2, ease: Power2.easeOut });
    gsap.to(this.container, { opacity:0, duration:.3, ease: Power2.easeIn, delay:.2, onComplete: () => {this.afterHide();}});
  }

  static afterHide() {
    super.afterHide();
    this.scroller.stop();

    gsap.killTweensOf(this.fake_bg);
    gsap.killTweensOf(this.bg);
    gsap.killTweensOf(this.holder);
    gsap.killTweensOf(this.sliderDom);

    gsap.set(this.container, { opacity: 1 });
    gsap.set(this.content, { opacity: 1 });
    gsap.set(this.holder, { height: 0 });
    gsap.set(this.sliderDom, { scale: 10, force3D: true });
    gsap.set(this.bg, { scaleY: 0, force3D: true });
    //gsap.set(this.fake_bg, { opacity: 0 });

  }

  static loop() {
    this.scroller.loop();
  }

  static resize(__first = false) {
    this.size = { width: this.container.offsetWidth, height: this.container.offsetHeight };
    gsap.set(this.content, { height: this.size.height });
    
    if(!__first) {
      this.links.resize();
      this.scroller.resize();
    }    
  }
}

class SidemenuSlider__Item extends InfiniteScroll__Item {
  image = GetBy.selector("img", this.dom)[0];
  
  constructor(__d, __i, __axis = InfiniteScroll.AXIS_Y) {
      super(__d, __i, __axis, true);
      this.image = GetBy.selector("img", this.dom)[0];
      /*this.image = new Image({dom:this.dom, hasFreePosition:true});
      this.title = this.dom.dataset.title;
      this.url = this.dom.dataset.url;
      FullSlider.getInstance().add(this.image);*/
  }

  drawHook() {
     this.move();
  }

  move() {
      const p = 1 - 2 * this.progressInside; 
      const scale = Maths.lerp(1, 1.2, Sidemenu.easing(Math.abs(p)));
      const origin = Maths.lerp(50, 60, p);
      
      this.image.style.transform = `scale3D(${scale},${scale},${scale})`;
      this.image.style.transformOrigin = `50% ${origin}%`;
  }
}
