import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__BillboardHome__Info extends VScroll_Item {
  _caption;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);
    this.text1 = GetBy.class('__text1', __link)[0];
    this.text2 = GetBy.class('__text2', __link)[0];
  }

  drawHook() {
   
    const normalize = this.progress < .5? Math.max(0, Maths.normalize(.3,.2, this.progress)) : Math.max(0, Maths.normalize(.75,.85, this.progress));
    const normalize2 = this.progress < .5? Math.max(0, Maths.normalize(.35,.25, this.progress)) : Math.max(0, Maths.normalize(.8,.9, this.progress));
    this.text1.style.opacity = normalize;
    this.text2.style.opacity = normalize2;
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('BillboardHome__Info', ScrollItem__BillboardHome__Info);
