import gsap, { Expo, Power2 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";
import { isDebug } from '../_app/cuchillo/core/Basics';

export default class Preloader {
    _container;
    _progress;
    _logoHolder;
    _logo;

    static init () {
        if(isDebug) GetBy.id('Preloader').style.display = "none";
    
        this._container = GetBy.id('Preloader');
        this._progress = GetBy.class('logo', this._container);
        this._logoHolder = GetBy.id('HeaderLogo');
        this._logo = GetBy.selector('svg', this._logoHolder);

        gsap.to(this._progress, {
            duration: 1,
            opacity: 1,
            delay: 0.2,
            ease: Power2.easeIn
        });

        gsap.to(this._progress, {
            duration: 3,
            scaleX:3,
            scaleY:3,
            delay: 0,
            ease: Power2.easeIn
        });
    }

    static update (__progress) {
        if(isDebug) return;
    }

    static hide (__call) {
        if(isDebug) {
            if(__call) __call();
            return;
        }

        gsap.to(this._progress, {
            duration: .3,
            opacity: 0,
            delay: 1,
        });

        gsap.to(this._container, {
            duration: .4,
            opacity: 0,
            delay: 1.1,
            onComplete: () => {
            this._container.style.display = "none";
            if(__call) __call();
            }
        });

        gsap.to(this._logo, {
            scaleX: 1,
            scaleY: 1,
            duration: 2,
            delay: .5,
            ease: Expo.easeOut
        });
    }
}
