import gsap, { Power2, Power3 } from "gsap";
import { GetBy } from "../core/Element";
import { Basics } from "../core/Basics";

export default class FluidLink {
  container;
  info;
  id;
  span;
  isOpen = false;
  isRunning = false;
  actual = "";
  
  _widthOpen;
  _widthTarget;
  _width = 0;
  _easing = .01;
  _height;
  text;
  textHover;

  _currentIcon;

  _topTarget = 0;
  _top = 0;
  _nTitles = 0;
  _topHolder = 0;
  _topHolderTarget = 0;

  _idTimer;

  _calls = {
    loop: () => this.loop(),
    over: () => this.change(this.textHover),
    out: () => this.change(this.text),
    clik: () => this.loop()
  }

  get width() { return this._width; }
  set width(__n) {
    this._width = __n;
    this.holder.style.width = `${this._width}px`;
  }

  get topHolder() { return this._topHolder; }
  set topHolder(__n) {
    this._topHolder = __n;
    this.holder.style.transform = `translate3D(0, ${this._topHolder}px, 0)`;
  }

  constructor(dom, __isHide = false) {
    this.container = dom;
    this.info = dom;
    this.useElement = GetBy.selector("use", this.container)[0];
    this.holder = GetBy.selector("span", this.container)[0];
    this.span = GetBy.selector("span", this.container)[1];
    this.text = this.span.textContent;
    this.textHover = this.container.dataset.textHover;
    
    if(!__isHide) {
      this.addListeners();
      this.enableLoop();
    } else {
      gsap.set(this.container, {opacity:0});
    }

    this._widthTarget =
      this._width =
        this._widthOpen = this.span.offsetWidth;
    
    this.resize();
  }


  change(__title, __href, __currentPage) {
    this.waitingCall = null;
    
    if(__currentPage) {
      this.container.setAttribute("aria-current", "page");
    }
    
    if(this.actual == __title) {
      //return;
    }

    this.actual = __title;   
    this.newSpan = document.createElement("span");
    this.newSpan.innerHTML = __title;
    this.span = this.newSpan;
    this.holder.appendChild(this.newSpan);
    
    this._widthOpen = this.newSpan.offsetWidth;
    this._widthTarget = this._widthOpen;

    this._nTitles++;
    this._topHolderTarget = -this._height *  this._nTitles;
  }

  changeIcon(__icon) {
    if(this._currentIcon == __icon) return;
    
    if (this.useElement) {
      const currentHref = this.useElement.getAttribute('xlink:href');
      const newHref = currentHref.replace(/#.*$/, `#${__icon}`);
      this.useElement.setAttribute('xlink:href', newHref);
    }
  }

  addListeners() {
    if(this.textHover) {
      this.container.addEventListener(Basics.mouseOver, this._calls.over);
      this.container.addEventListener(Basics.mouseOut, this._calls.out);
    }
  }

  removeListeners() {
    if(this.textHover) {
      this.container.removeEventListener(Basics.mouseOver, this._calls.over);
      this.container.removeEventListener(Basics.mouseOut, this._calls.out);
    }
  }

  show(duration = 1, delay = 0) {
    this.addListeners();
    this.enableLoop();
    
    gsap.killTweensOf(this.container);
    gsap.to(this.container, {opacity:1, duration:duration, ease:Power3.easeOut, delay:delay});
    
  }

  hide(duration = .4, delay = 0) {
    this.removeListeners();      
    gsap.killTweensOf(this.container);
    this.change("&nbsp;")
    /*gsap.set(this.container, {opacity:0});
    this.reset();      */

    gsap.to(this.container, {opacity:0, duration:.3, ease:Power3.easeOut, delay:delay, onComplete: () => {
      this.reset();      
    }});
  }

  reset() {
    this.holder.innerHTML = '';
    this.topHolder = 0;
    this._topHolderTarget = 0;
    this._nTitles = 0;
    this.span = document.createElement("span");
    this.span.textContent = this.text;
    this.holder.appendChild(this.span);
    this.disableLoop();
  }

  enableLoop() {
    if(this.isRunning) return;
    this.isRunning = true;

    gsap.ticker.add(this._calls.loop);
  }

  disableLoop() {
    if(!this.isRunning) return;
    this.isRunning = false;

    gsap.ticker.remove(this._calls.loop);
  }

  resize() {
    this._height = this.span.getBoundingClientRect().height;
    this._widthOpen = this.span.offsetWidth;
        
    this.topHolder =
      this._topHolderTarget = -this._height *  this._nTitles;
  }

  loop() {
    this.speed = (this._widthTarget - this._width) * 0.1;
    this.width = this._width + this.speed;

    this.speedTopHolder = (this._topHolderTarget - this._topHolder) * .1;
    this.topHolder = this._topHolder + this.speedTopHolder;
  }
}

export class FluidLinkGroup {
  items;
  timer

  constructor(selector = "[data-fluid-link]", container = document) {
    this.items = [...GetBy.selector(selector, container)].map(item => new FluidLink(item));
  }

  show(__delay = 0) {
    const delay = __delay;
    const time = 1.6;
    let cont = 0;

    this.items.forEach(item => {
      item.show(time + 0.6 * cont, delay + 0.1 * cont)
      cont++;
    });
  }

  hide() {
    const delay = 0;
    const time = .4;
    let cont = 0;
    
    this.items.forEach(item => {
      item.hide(time + 0.1 * cont, delay + 0.05 * cont)
      cont++;
    });
  }

  resize () {
    this.items.forEach(item => item.resize());
  }
}