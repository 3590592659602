import gsap, { Power3, Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Ease } from '../_app/cuchillo/utils/Ease';
import { isMobile, isSmartphone } from '../_app/cuchillo/core/Basics';

class ScrollItem__BillboardIndex extends VScroll_Item {
  _bg;
  _title;
  _info;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._call = () => this.loop();

    this._info = GetBy.class('__info', __link);
    gsap.set(this._info, { opacity: 0 });

    this._bg = GetBy.selector('figure', __link);

    if(!isSmartphone) {
      this._title = new SplitText(GetBy.selector('h1', __link)[0], {
        type: 'words',
      }).words;
      gsap.set(this._title, { y: '100%' });
    }

    if (isMobile) this.setMobile();
    else this.setDesktop();

    this.onShow = () => {
      gsap.ticker.add(this._call);
    };
    this.onHide = () => {
      gsap.ticker.remove(this._call);
    };
    this.onMove = () => { };

    if (isMobile) this.showMobile();
    else this.showDesktop();
  }

  setMobile() { }

  setDesktop() {
    this._left = GetBy.selector('.left', this.item);
    this._right = GetBy.selector('.right', this.item);

    gsap.set(this._left, {
      width: '100%'
    });
    gsap.set(this._right, {
      x: "100%", force3D: true
    });
  }

  showMobile() { }

  showDesktop() {
    gsap.ticker.add(this._call);

    let delay = 0;

    gsap.to(this._left, {
      width: '50%',
      duration: 2,
      delay,
      ease: Ease.EASE_CUCHILLO_OUT
    });
    gsap.to(this._right, {
      x: '0%',
      duration: 2,
      force3D: true,
      delay,
      ease: Ease.EASE_CUCHILLO_OUT
    });

    delay += .1;
    for (let i = 0; i < this._title.length; i++) {
      const item = this._title[i];
      gsap.to(item, {
        y: 0,
        duration: 1.2,
        delay: delay + i * 0.2,
        ease: Ease.EASE_CUCHILLO_OUT
      });
    }

    delay += .3;
    gsap.to(this._info, {
      opacity: 1,
      duration: 1,
      delay,
      ease: Power2.easeOut
    });
  }

  loop() {
    const y = Math.max(Maths.map(this.progress, .5, 1, 0, 30), 0);
    const scale = Math.max(Maths.map(this.progress, .5, 1, 1, 1.4), 1);
    const blur = Math.max(Maths.map(this.progress, .5, 1, 0, 50), 0);

    if (isMobile) gsap.set(this._bg, { y: `${y}%`, filter: `blur(${blur}px)`, scale });
    else gsap.set(this._bg, { y: `${y}%`, scale });
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('BillboardIndex', ScrollItem__BillboardIndex);
