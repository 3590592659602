import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Default from './Default';
import Header from '../layout/Header';

export default class Home extends Default {
  headerMode = Header.HEADER_MODE_HOME;

  constructor() {
    super();
  }

  beforeShow() {
    super.beforeShow();
  }
}

ControllerPage._addPage('home', Home);
