import gsap, { Power2, Power3 } from "gsap";
import { GetBy } from "../core/Element";
import { Basics } from "../core/Basics";

export default class FluidNavLink {
  container;
  info;
  id;
  span;
  isOpen = false;
  isRunning = false;
  actual = "";

  _widthOpen;
  _widthTarget;
  _width = 0;
  _easing = .01;
  _height;
  text;

  _topTarget = 0;
  _top = 0;
  _nTitles = 0;
  _topHolder = 0;
  _topHolderTarget = 0;

  isLink = true;

  _calls = {
    loop: () => this.loop(),
    over: () => this.change(this.text),
    clik: () => this.loop()
  }

  get width() { return this._width; }
  set width(__n) {
    /* this._width = __n;
     this.container.style.width = `${this._width}px`;*/
  }

  get topHolder() { return this._topHolder; }
  set topHolder(__n) {
    this._topHolder = __n;
    this.holder.style.transform = `translate3D(0, ${this._topHolder}px, 0)`;
  }

  constructor(dom, __isLink = true, __easing = .2) {
    this.container = dom;
    this.info = dom;
    this.holder = GetBy.selector("span", this.container)[0];
    this.span = GetBy.selector("span", this.container)[1];
    this.id = this.container.getAttribute("id");
    this._easing = __easing;
    this.isLink = __isLink;
    this.text = this.span.textContent;

    if(__isLink)  this.container.style.opacity = 0;

    this.resize();
  }

  change(__title, __href, __currentPage, __noRepeat = false) {
    this.waitingCall = null;

    if (__currentPage) {
      this.container.setAttribute("aria-current", "page");
    }

    if (this.actual == __title && __noRepeat) {
      return;
    }

    this.actual = __title;

    /* this.container.setAttribute("href", __href);
     this.info.setAttribute("href", `${__href}details/`);*/



    this.newSpan = document.createElement("span");
    this.newSpan.textContent = __title;

    gsap.killTweensOf(this.span);
    gsap.to(this.span, { opacity: 0, duration: .2, ease: Power2.easeIn });
    gsap.to(this.newSpan, { opacity: 1, duration: .2, ease: Power2.easeOut });

    this.span = this.newSpan;
    this.holder.appendChild(this.newSpan);

    this._widthOpen = this.newSpan.offsetWidth;
    this._widthTarget = this._widthOpen;

    this._nTitles++;

    this._topHolderTarget = -this._height * this._nTitles;
  }

  show(duration = 1, delay = 0) {
    if(!this.isLink)  return;

    this.container.addEventListener(Basics.mouseOver, this._calls.over);
    this.container.addEventListener(Basics.mouseOut, this._calls.over);

    gsap.killTweensOf(this.container);
    gsap.to(this.container, { opacity: 1, duration: duration, ease: Power3.easeOut, delay: delay });
    gsap.ticker.add(this._calls.loop);
  }

  hide(duration = .4, delay = 0) {
    if(!this.isLink)  return;

    gsap.killTweensOf(this.container);
    gsap.to(this.container, {
      opacity: 0, duration: duration, ease: Power3.easeIn, delay: delay, onComplete: () => {
        this.container.removeEventListener(Basics.mouseOver, this._calls.over);
        this.container.removeEventListener(Basics.mouseOut, this._calls.over);
        gsap.ticker.remove(this._calls.loop);
        this.reset();
      }
    });
  }

  reset() {
    this.holder.innerHTML = '';
    this.topHolder = 0;
    this._topHolderTarget = 0;
    this._nTitles = 0;
    this.span = document.createElement("span");
    this.span.textContent = this.text;
    this.holder.appendChild(this.span);
  }

  enableLoop() {
    if (this.isRunning) return;
    this.isRunning = true;

    gsap.ticker.add(this._calls.loop);
  }

  disableLoop() {
    if (!this.isRunning) return;
    this.isRunning = false;

    gsap.ticker.remove(this._calls.loop);
  }

  resize() {
    this._height = this.span.getBoundingClientRect().height;

    this.topHolder =
      this._topHolderTarget = -this._height * this._nTitles;
  }

  loop() {
    this.speedTopHolder = (this._topHolderTarget - this._topHolder) * this._easing;
    this.topHolder = this._topHolder + this.speedTopHolder;
  }
}

export class FluidNavLinkGroup {
  items;
  timer

  constructor(selector = "[data-fluid-navlink]", container = document) {
    this.items = [...GetBy.selector(selector, container)].map(item => new FluidNavLink(item));
  }

  show(__delay = 0) {
    const delay = __delay;
    const time = 1.6;
    let cont = 0;

    this.items.forEach(item => {
      item.show(time + 0.6 * cont, delay + 0.1 * cont)
      cont++;
    });
  }

  hide() {
    const delay = 0;
    const time = .4;
    let cont = 0;

    this.items.forEach(item => {
      item.hide(time + 0.1 * cont, delay + 0.05 * cont)
      cont++;
    });
  }

  resize() {
    this.items.forEach(item => item.resize());
  }
}