import { gsap } from "gsap";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { ScrollItem__SliderFullscreen } from './ScrollItem__SliderFullscreen';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Basics } from "../_app/cuchillo/core/Basics";
import { Ease } from "../_app/cuchillo/utils/Ease";
import { CSS } from "../_app/cuchillo/utils/CSS";

class ScrollItem__SliderVisor extends ScrollItem__SliderFullscreen {
    _visor;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        const visor = GetBy.class('__visor', __link.parentNode)[0];
        if (visor) this._visor = new Visor(visor);
    }

    showFunction() {
        super.showFunction();
    }

    loop() {
        super.loop();
        if (this._visor) this._visor.loop(this.progress, this._slider.actual);
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._visor) this._visor.resize();
    }

    dispose() {
        super.dispose();
        if (this._visor) this._visor.dispose();
    }
}

class Visor {
    dom;
    holder;
    visible = false;
    images = [];
    current = 0;

    positions = {
        visor: {
            x: 0,
            y: 0,
            xOrigin: 0.5,
            yOrigin: 0.5,
            frame: 0
        },
        bg: {
            x: 0,
            y: 0
        }
    }

    sizes = {
        center: {
            x: 0,
            y: 0
        }
    }

    constructor(__dom) {
        this.dom = __dom;

        this.directHide();
        this.holder = GetBy.class('holder', __dom)[0];
        this.images = GetBy.selector('img', __dom);
    }

    show(p) {
        this.visible = true;
        gsap.to(this.dom, { opacity: 1, duration: .2 });
    }

    directHide() {
        this.visible = false;
        gsap.set(this.dom, { opacity: 0 });
    }

    hide(p) {
        this.visible = false;
        gsap.to(this.dom, { opacity: 0, duration: .2 });
    }

    loop(p, current) {
        if (!this.visible && p <= .75 && p >= .25) this.show(p);
        else if (this.visible && (p > .75 || p < .25)) this.hide(p);

        const x = Interaction.positions.mouse.x - Metrics.CENTER_X;
        const y = Interaction.positions.mouse.y - Metrics.CENTER_Y;

        const pX = Interaction.positions.mouse.x/Metrics.WIDTH;
        const pY = Interaction.positions.mouse.y/Metrics.HEIGHT;

        this.positions.visor.x = Maths.lerp(this.positions.visor.x, x, .06);
        this.positions.visor.y = Maths.lerp(this.positions.visor.y, y, .06);
        gsap.set(this.dom, { x: this.positions.visor.x, y: this.positions.visor.y });

        /*const dispx = Maths.clamp((Interaction.positions.mouse.x / Metrics.WIDTH - .5) * 100, -20, 20);
        const dispy = Maths.clamp((Interaction.positions.mouse.y / Metrics.HEIGHT - .5) * 100, -20, 20);
        this.positions.bg.x = Maths.lerp(this.positions.bg.x, dispx, .045);
        this.positions.bg.y = Maths.lerp(this.positions.bg.y, dispy, .045);
        gsap.set(this.holder, { x: `-${this.positions.bg.x}%`, y: `-${this.positions.bg.y}%` });*/

       
        this.positions.visor.frame = Maths.lerp(this.positions.visor.frame, -100 * current, .06);
        this.positions.visor.xOrigin = Maths.lerp(this.positions.visor.xOrigin, pX, .06);
        this.positions.visor.yOrigin = Maths.lerp(this.positions.visor.yOrigin, pY, .06);
        this.holder.style.setProperty('--y-origin', `${100 * this.positions.visor.yOrigin}%`);
        this.holder.style.setProperty('--x-origin', `${100 * this.positions.visor.xOrigin}%`);
        
       // if (this.current !== current) {
            this.current = current;
            //gsap.killTweensOf(this.images);
            gsap.set(this.images, { x: `${this.positions.visor.frame}%` });
        //}
    }

    resize() {
        const sizes = this.dom.getBoundingClientRect();
        this.sizes.center = {
            x: sizes.width / 2,
            y: sizes.height / 2
        }
    }

    dispose() {

    }
}


Scroll._registerClass('SliderVisor', ScrollItem__SliderVisor);
