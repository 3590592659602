import { gsap, Power2 } from "gsap";

import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { Ease } from '../_app/cuchillo/utils/Ease';
import { isMobile } from "../_app/cuchillo/core/Basics";

class ScrollItem__Keywords extends VScroll_Item {
    _targetImg;
    _target;
    _bg;
    _bgFigure;
    _bgImg;

    _call = {
        loop: () => this.loop()
    }

    _positions = {
        visor: {
            x: 0,
            y: 0
        },
        bg: {
            x: 0,
            y: 0
        }
    }

    _sizes = {
        x: 0,
        y: 0
    }

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if (!isMobile) {
            this._bg = GetBy.selector('.__bg', __link)[0];
            this._bgImg = GetBy.selector('.__bg img', __link)[0];
            this._bgFigure = GetBy.selector('.__bg figure', __link)[0];
           /* gsap.set(this._bgFigure, {
                scaleX: 1.3,
                scaleY: 1.3,
                
            });*/
            this._target = GetBy.selector('.__target', __link)[0];
            this._targetImg = GetBy.selector('.__target img', __link)[0];
        }

        this.onShow = () => {
            if (!isMobile) {
                let delay = 0;
                gsap.to(this._bgFigure, {
                    opacity: 1,
                    scaleX: 1,
                    scaleY: 1,
                    duration: 2.5,
                    delay,
                    ease: Ease.EASE_CUCHILLO_OUT
                });

                delay += .5;
                gsap.to(this._bgFigure, {
                    duration: .5,
                    filter: 'blur(0px)',
                    delay,
                    ease: Power2.easeOut,
                    onComplete: () => {
                        gsap.ticker.add(this._call.loop);
                    }
                });
            }
        };

        this.onHide = () => {
            if (isMobile) return;

            gsap.ticker.remove(this._call.loop);
        };
    }

    loop() {
        if (isMobile) return;

        const x = Maths.clamp((Interaction.positions.mouse.x / Metrics.WIDTH - .5) * 10, -10, 10);
        const y = Maths.clamp((Interaction.positions.mouse.y / Metrics.HEIGHT - .5) * 10, -10, 10);

        this._positions.visor.x = Maths.lerp(this._positions.visor.x, x, .06);
        this._positions.visor.y = Maths.lerp(this._positions.visor.y, y, .06);
        gsap.set(this._target, { x: `${this._positions.visor.x}%`, y: `${this._positions.visor.y}%` });

        this._positions.bg.x = Maths.lerp(this._positions.bg.x, x * .15, .045);
        this._positions.bg.y = Maths.lerp(this._positions.bg.y, y * .15, .045);
        gsap.set(this._bg, { x: `${this._positions.bg.x}%`, y: `${this._positions.bg.y}%` });
    }

    resize(w, h) {
        super.resize(w, h);

        if (isMobile) return;

        const { width, height } = this._bgImg.getBoundingClientRect();
        gsap.set(this._targetImg, { width, height });

        const sizes = this._target.getBoundingClientRect();
        this._sizes = {
            x: sizes.width / 2,
            y: sizes.height / 2
        }
    }
}

Scroll._registerClass('BlockKeywords', ScrollItem__Keywords);
