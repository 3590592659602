import { GetBy } from '../_app/cuchillo/core/Element';
import _Header from '../_app/cuchillo/layout/Header';
import { Power3, Power4 } from "gsap";
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { CSS } from '../_app/cuchillo/utils/CSS';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { isMobile } from '../_app/cuchillo/core/Basics';

export default class Header extends _Header {
  static HEADER_MODE_HOME = "home";
  static HEADER_MODE_DEFAULT = "default";

  static _mode = this.HEADER_MODE_DEFAULT;
  static get mode() { return this._mode; }
  static set mode(__mode) {
    if (this._mode == __mode) return;

    this._mode = __mode;

    if (__mode == this.HEADER_MODE_HOME) {
      this.enableDecoLogo();
      this.resize(true);
    } else {
      this.disableDecoLogo();
    }
  }

  static options = {
    show: {
      duration: .4,
      delay: 0,
      ease: Power4.easeOut
    },
    hide: {
      duration: .3,
      delay: 0,
      ease: Power3.easeIn
    }
  }

  static logoCheck = GetBy.class("logo", this.container)[0];
  static logoMain = GetBy.id("HeaderLogo");
  static menuHolder = GetBy.class("__menu")[0];
  static position = {}

  static init() {
    this.resize(true);

    this.mode = this.HEADER_MODE_HOME;
    setTimeout(() => this.resize(true), 500);
  }

  static enableBlurMode(__enable) {
    if (__enable) this.container.classList.add("--blur-mode");
    else this.container.classList.remove("--blur-mode");
  }

  static enableDecoLogo(__mode) {
    document.body.classList.add("--mode-logo");
  }

  static disableDecoLogo(__mode) {
    document.body.classList.remove("--mode-logo");
  }

  static forceWhite(__force = true) {
    if (__force) this.container.classList.add("--force-white");
    else this.container.classList.remove("--force-white");
  }

  static resize(__force = false) {
    if(isMobile && !__force) return;
    this.height = this.menuHolder.getBoundingClientRect().top + this.menuHolder.offsetHeight;

    this.position = {
      y0: this.logoMain.getBoundingClientRect().top,
      y1: this.logoCheck.getBoundingClientRect().top,
      distance: this.logoMain.getBoundingClientRect().top - this.logoCheck.getBoundingClientRect().top,
      minScale: this.logoCheck.offsetWidth / this.logoMain.offsetWidth,
    }

    this.minY = -this.position.distance;
  }

  static loop() {
    super.loop();

    if (this._y === -this.height) {
      this.container.classList.add("--symbol");
    } else {
      this.container.classList.remove("--symbol");
    }

    if (Interaction.positions.mouse.y <= this.height) {
      this.show();
    }

    if (this.mode == this.HEADER_MODE_HOME) {
      const scale = Math.max(0, Math.min(1, Math.abs(Scroll.y) / this.position.distance));
      const scaleTo = Maths.lerp(1, this.position.minScale, scale);

      if (scaleTo == this.position.minScale) {
        this.disableDecoLogo();
        this.logoMain.style[CSS.transform] = CSS.translate3D(0, -this.position.distance, 1) + " " + CSS.scale(this.position.minScale);
      } else {
        this.enableDecoLogo();
        this.logoMain.style[CSS.transform] = CSS.translate3D(0, Scroll.y, 1) + " " + CSS.scale(scaleTo);
      }
    }
  }

}


