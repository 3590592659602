import { Videos } from '../_app/cuchillo/components/Videos';
import { Acordions } from '../_app/cuchillo/components/Acordions';
import { Basics, isMobile, isTouch } from '../_app/cuchillo/core/Basics';
import Forms from '../_app/cuchillo/forms/FormValidator';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
// import Scrollbar from '../_app/cuchillo/scroll/Scrollbar';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import { GetBy } from '../_app/cuchillo/core/Element';
import Cmp from '../windows/Cmp';
import Header from '../layout/Header';
import FluidCursor from '../components/FluidCursor';

export default class Default extends Page {
  indexContainer;
  servicesContainer;
  headerMode = Header.HEADER_MODE_DEFAULT;

  constructor() {
    super();

    Videos.init();
    Acordions.init();
    Forms.init();
    FluidCursor.init();

    [...GetBy.selector('[data-toggle-cmp]')].map(item => {
      item.addEventListener(Basics.clickEvent, Cmp._calls.click);
    });

    const indexContainer = GetBy.id('IndexTarget');
    if (indexContainer) {
      this.indexContainer = indexContainer;
      this.buildIndex();
    }

    const servicesContainer = GetBy.id('ServicesTarget');
    if (servicesContainer) {
      this.servicesContainer = servicesContainer;
      this.buildServices();
    }
  }

  buildIndex() {
    const sections = [...GetBy.selector('[data-section-index]'), ...GetBy.selector('[data-section-index-aux]'), ...GetBy.selector('[data-gallery-section]')];
    for (let i = 0; i < sections.length; i++) {
      const sect = sections[i];

      const li = document.createElement('li');
      const a = document.createElement('a');
      a.classList.add("link-underline");
      a.classList.add("--invert");
      a.classList.add("--with-number");
      a.href = sect.href? sect.href : '#' + sect.dataset.sectionId;
      a.innerHTML = `<span>${i + 1}</span><span>${sect.dataset.sectionName}</span>`;
      this.indexContainer.appendChild(li);
      li.appendChild(a);
    }
  }

  buildServices() {
    const services = GetBy.selector('[data-service]');
    for (let i = 0; i < services.length; i++) {
      const serv = services[i];

      const li = document.createElement('li');
      const a = document.createElement('a');
      a.classList.add("link-underline");
      a.classList.add("--invert");
      a.classList.add("--with-number");
      a.href = serv.href;
      a.innerHTML = `<span>${i + 1}</span><span>${serv.dataset.service}</span>`;
      this.servicesContainer.appendChild(li);
      li.appendChild(a);
    }
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Header.mode = this.headerMode;
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    super.afterShow();
    Scroll.show();
    Scroll.start();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    Scroll.dispose();
    super.afterHide();

    [...GetBy.selector('[data-toggle-cmp]')].map(item => {
      item.removeEventListener(Basics.clickEvent, Cmp._calls.click);
    });
  }

  //RESIZE
  resize() {
    super.resize();
    Acordions.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);
