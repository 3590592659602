import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Default from './Default';
import Header from '../layout/Header';
import FluidCursor from '../components/FluidCursor';

export default class NoScroll extends Default {
  constructor() {
    super();
    FluidCursor.init();
  }

  beforeShow() {
    //Header.hide();
  }

  afterShow() {
    this._isActive = true;
    document.body.classList.add("__noScroll");
  }

  afterHide() {
    super.afterHide();
    document.body.classList.remove("__noScroll");
  }
}

ControllerPage._addPage('no-scroll', NoScroll);
