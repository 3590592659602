import { gsap } from 'gsap';

import { GetBy } from '../core/Element';
import VScroll from '../scroll/VScroll';
import Scrollbar from '../scroll/Scrollbar';
import { MrInteraction } from '../core/Interaction';
import { Basics } from '../core/Basics';
import VScroll_Item from '../scroll/VScroll_Item';

export class SliderHorizontalScroll {
    _container;
    _holder;
    _scroll;
    _scrollBar;
    _interaction;
    _step;
    _call;
    _steps = [];

    get size() {
        return this._container.offsetWidth + this._scroll.size;
    }

    get sizeOffScreen() {
        return this._scroll.size - this._container.offsetWidth;
    }

    get progress() {
        return this._scroll.progress;
    }

    set progress(progress) {
        this._scroll.goto_percetage(progress, true);
    }

    get total() {
        return this._scroll.total_items;
    }

    get actual() {
        return this._steps.findIndex(
            ({ start, end }) => this.progress >= start && this.progress < end
        );
    }

    get items() {
        return this._scroll._items;
    }

    constructor(__container, options = {}) {
        this._container = __container;
        this._holder = GetBy.class('__holder', __container)[0];

        console.log(options)

        this._scroll = new VScroll({
            container: __container,
            axis: 'X',
            wheel: false,
            itemClass: options.itemClass || VScroll_Item,
            easing: options.easing,
            smooth: options.smooth,
            hasLimits: options.hasLimits
        });

        if (options.hasScrollbar) {
            this._scrollBar = new Scrollbar(GetBy.class('scrollbar', this._container)[0]);
            this._scroll.setScrollbar(this._scrollBar);
            this._scrollBar.update(0);
        }

        this._scroll.addAll('[scroll-slider-item]');
        this._scroll.resize();
        this._scroll.loop(true);
        this._scroll.start();

        this._step = (1 / (this.total - 1));

        if (!options.interaction === false) {

            this._interaction = new MrInteraction(this._holder, {
                drag: true,
                axis: 'x',
                dragCheckTime: .05,
                onMove: (n) => {
                    if (options.onMove) options.onMove();
                    this._scroll.move(n)
                },
                onDragStart: () => {
                    if (options.onDragStart) options.onDragStart();
                },
                onDragEnd: () => {
                    if (options.onDragEnd) options.onDragEnd();
                }
            });
        }

        /* CONTROLS */
        this._controls = {
            next: GetBy.selector('[scroll-slider-next]', options.domControls || __container)[0],
            prev: GetBy.selector('[scroll-slider-prev]', options.domControls || __container)[0],
            current: GetBy.selector('[scroll-slider-current]', options.domControls || __container)[0],
            total: GetBy.selector('[scroll-slider-total]', options.domControls || __container)[0],
            progress: GetBy.selector('[scroll-slider-progress]', options.domControls || __container)[0]
        }

        if (this._controls.next) {
            this._controls.next.addEventListener(Basics.clickEvent, (e) => {
                this.next();
            });
        }

        if (this._controls.prev) {
            this._controls.prev.addEventListener(Basics.clickEvent, (e) => {
                this.prev();
            });
        }

        if (this._controls.total) {
            this._controls.total.innerHTML = this.total;
        }

        if (this._controls.progress) {
            gsap.to(this._controls.progress, { drawSVG: `${0}% ${Math.ceil(this.progress * 100)}%` });
        }

        this._call = () => this.loop();
    }

    calculateSteps() {
        const totalWidth = Array.from(this.items).reduce(
            (acc, item) => acc + item.offsetWidth,
            0
        );

        let accumulatedWidth = 0;

        // Calculamos los rangos de cada slide en función de su ancho
        this._steps = Array.from(this.items).map((item) => {
            const start = accumulatedWidth / totalWidth;
            accumulatedWidth += item.offsetWidth;
            const end = accumulatedWidth / totalWidth;

            return { start, end };
        });
    }

    start() {
        gsap.ticker.add(this._call);
    }

    stop() {
        gsap.ticker.remove(this._call);
    }

    step(__step) {
        this._scroll.gotoStep(__step)
    }

    directGoto(__n) {
        this._scroll.gotoStep(__n)
    }

    next() {
        this.goto_percetage(Math.min(this.actual + 1, this.total - 1) * this._step);
    }

    prev() {
        this.goto_percetage(Math.max(this.actual - 1, 0) * this._step);
    }

    goto_percetage(__p) {
        this._scroll.goto_percetage(__p, true);
    }

    loop() {
        if (this._controls.current) {
            this._controls.current.innerHTML = Math.max(Math.ceil(Maths.lerp(0, this.total, this.progress)), 1);
        }

        if (this._controls.prev) {
            if (this.actual === 0) {
                this._controls.prev.classList.add('disabled');
            } else {
                this._controls.prev.classList.remove('disabled');
            }
        }

        if (this._controls.next) {
            if (this.total - 1 === this.actual) {
                this._controls.next.classList.add('disabled');
            } else {
                this._controls.next.classList.remove('disabled');
            }
        }

        if (this._controls.progress) {
            gsap.to(this._controls.progress, { drawSVG: `${0}% ${this.progress * 100}%` });
        }

        this._scroll.loop();
    }

    resize() {
        this._scroll.resize();
    }

    dispose() {
        this._scroll.dispose();
        if (this._interaction) {
            this._interaction.dispose();
        }
        if (this._scrollBar) {
            this._scrollBar.dispose();
        }
    }
}

