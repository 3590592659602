import BezierEasing from 'bezier-easing';

import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { isMobile } from '../_app/cuchillo/core/Basics';

export class ScrollItem__SliderFullscreen extends VScroll_Item {
    _call;
    _slider;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if (!isMobile) {
            this._slider = new SliderScroll(__link, {
                interaction: true,
                hasScrollbar: false,
                itemClass: ScrollItem__SliderImages__Item
            });
        }

        this._call = () => this.loop();
        this.onShow = () => {
            this.showFunction();
        };

        this.onHide = () => {
            gsap.ticker.remove(this._call);
        };
    }

    showFunction() {
        gsap.ticker.add(this._call);
    }

    resize(w, h) {
        super.resize(w, h);
        if (this._slider) this._slider.resize();
    }

    loop() {
        if (this._slider) this._slider.loop();
    }

    dispose() {
        if (this._slider) this._slider.dispose();
        super.dispose();
    }
}

class ScrollItem__SliderImages__Item extends VScroll_Item {
    easing = BezierEasing(0.69, 0.27, 0.69, 0.28);
    hasHiddenEnabled = false;
    image;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this.image = GetBy.selector('img', __link)[0];

        this.onShow = () => { };
        this.onHide = () => { };
        this.onMove = () => {
            this.loop();
        };
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================
    loop() {
        if (!this.image) return;

        const p = this.progress - .5;
        const scale = Math.max(Maths.lerp(1, 3, this.easing(Math.abs(p))), 1);
        const origin = Maths.clamp(Maths.lerp(50, 100, p), 5, 95);

        this.image.style.transform = `scale3D(${scale}, ${scale}, ${scale})`;
        this.image.style.transformOrigin = `${origin}% 50%`;
    }

    //   mouseOver () { }
    //   mouseDown () { }
    //   mouseUp () { }

    //   show () {
    //     super.show();
    //   }

    //   hide () {
    //     super.hide();
    //   }
}

Scroll._registerClass('SliderFullscreen', ScrollItem__SliderFullscreen);
