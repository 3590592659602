import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Ease } from '../_app/cuchillo/utils/Ease';
import { Basics } from '../_app/cuchillo/core/Basics';

class ScrollItem__NextProject extends VScroll_Item {
  _bg;
  _title;
  _projectName;
  _nTitles = 0;
  _topHolder = 0;
  _topHolderTarget = 0;

  wrapper;
  holder;
  span = GetBy.selector("span", this.container)[1];

  isHover = false;

  _calls = {
    loop: () => this.loop(),
    mouseOver: () => this.mouseOver(),
    mouseOut: () => this.mouseOut()
  }

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  get topHolder() { return this._topHolder; }
  set topHolder(__n) {
    this._topHolder = __n;
    this.holder.style.transform = `translate3D(0, ${this._topHolder}px, 0)`;
  }

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._projectName = __link.dataset.text;
    this.wrapper = GetBy.selector('a', __link)[0];
    this.holder = GetBy.selector('span', __link)[0];
    this.span = GetBy.selector('span', this.holder)[0];

    this._bg = GetBy.selector('figure', __link)[0];
   
    this._title = new SplitText(GetBy.selector('h2', __link)[0], {
      type: 'chars',
    }).chars;
    gsap.set(this._title, { y: '110%' });

    this.onShow = () => {
      let delay = .4;
      this.wrapper.addEventListener(Basics.mouseOver, this._calls.mouseOver);
      this.wrapper.addEventListener(Basics.mouseOut, this._calls.mouseOut);
      gsap.ticker.add(this._calls.loop);

      for (let i = 0; i < this._title.length; i++) {
        const item = this._title[i];
        gsap.to(item, {
          y: 0,
          duration: 1.2,
          delay: delay + i * 0.1,
          ease: Ease.EASE_CUCHILLO_OUT
        });
      }
    };
    this.onHide = () => {
      gsap.ticker.remove(this._call);

    };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  mouseOver() {
    this.newSpan = document.createElement('span');
    this.newSpan.textContent = this._projectName;

    gsap.killTweensOf(this.span);
    gsap.to(this.span, { opacity: 0, duration: .2, ease: Power2.easeIn });
    gsap.to(this.newSpan, { opacity: 1, duration: .2, ease: Power2.easeOut });

    this.span = this.newSpan;
    this.holder.appendChild(this.newSpan);

    this._nTitles++;

    this._topHolderTarget = -this._height * this._nTitles;

    this.isHover = !this.isHover;
  }

  mouseOut() {
    if (!this.isHover) return;

    this.mouseOver();
  }

  loop() {
    this.speedTopHolder = (this._topHolderTarget - this._topHolder) * .2;
    this.topHolder = this._topHolder + this.speedTopHolder;
  }

  resize() {
    super.resize();

    this._height = this.span.getBoundingClientRect().height;

    this.topHolder =
      this._topHolderTarget = -this._height * this._nTitles;
  }

  dispose() {
    super.dispose();
    this.wrapper.removeEventListener(Basics.mouseOver, this._calls.mouseOver);
    this.wrapper.removeEventListener(Basics.mouseOut, this._calls.mouseOut);
  }
}

Scroll._registerClass('NextProject', ScrollItem__NextProject);
