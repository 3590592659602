import gsap, { Power2 } from 'gsap';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { isMobile } from '../_app/cuchillo/core/Basics';

class ScrollItem__TextSteps extends VScroll_Item {
  _slides = [];
  _numbers;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    if (!isMobile) {
      this._numbers = new NumbersHolder(GetBy.class('__numbers', __link)[0]);
      const numbers = GetBy.class('__number', __link);
      [...GetBy.class('__slide', __link)].map((item, i) => {
        this._slides.push(new Steps__Item(item, numbers[i], i));
      });
    }
  }

  drawHook() {
    if (isMobile) return;

    let active = 0;
    const center = Metrics.HEIGHT / 2;

    this._slides.forEach((slide, i) => {
      const isVisible = (slide.top >= 0 && slide.bottom <= center || slide.top < center && slide.bottom > 0);
      if (isVisible) active = i;
    });

    this._slides.forEach((slide, i) => {
      if (i === active) slide.show();
      else slide.hide();

      slide.loop();
    });

    const p = Maths.clamp(1 - this.progressInside, 0, 1);
    this._numbers.loop(p);
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  resize(w, h) {
    super.resize(w, h);

    if (this._numbers) this._numbers.resize();
    if (this._slides) this._slides.forEach(slide => slide.resize());
  }
}

Scroll._registerClass('TextSteps', ScrollItem__TextSteps);

class Steps__Item {
  dom;
  index;
  activeClass = '--active';
  sizes = {
    top: 0,
    bottom: 0,
    left: 0,
    height: 0,
    width: 0,
    x: 0,
    y: 0
  };
  isActive = false;

  number;
  span;
  newSpan;
  _height = 0;
  _nTitles = 0;
  _topHolder = 0;
  _topHolderTarget = 0;

  get top() {
    return this.sizes.top + Scroll.y;
  }

  get bottom() {
    return this.sizes.bottom + Scroll.y;
  }

  get topHolder() { return this._topHolder; }
  set topHolder(__n) {
    this._topHolder = __n;
    this.number.style.transform = `translate3D(0, ${this._topHolder}px, 0)`;
  }

  constructor(__dom, __num, __i) {
    this.dom = __dom;
    this.index = __i + 1;

    this.number = GetBy.selector('span', __num)[0];
    this.span = GetBy.selector('span', __num)[1];
  }

  show() {
    if (!this.isActive) {
      this.isActive = true;
      this.dom.classList.add(this.activeClass);

      this.showNumber('--active', .1);
    }
  }

  hide() {
    if (this.isActive) {
      this.isActive = false;
      this.dom.classList.remove(this.activeClass);

      this.showNumber();
    }
  }

  showNumber(__c = undefined, __d = 0) {
    this.newSpan = document.createElement('span');
    this.newSpan.textContent = this.index;

    if (__c) this.newSpan.classList.add(__c);

    gsap.killTweensOf(this.span);
    gsap.to(this.span, { opacity: 0, duration: .2, delay: __d, ease: Power2.easeIn });

    this.span = this.newSpan;
    this.number.appendChild(this.newSpan);

    this._nTitles++;
    gsap.delayedCall(__d, () => {
      this._topHolderTarget = -this._height * this._nTitles;
    });
  }

  loop() {
    this.speedTopHolder = (this._topHolderTarget - this._topHolder) * .2;
    this.topHolder = this._topHolder + this.speedTopHolder;
  }

  resize() {
    this.sizes = this.dom.getBoundingClientRect();

    this._height = this.span.getBoundingClientRect().height;

    this.topHolder =
      this._topHolderTarget = -this._height * this._nTitles;
  }
}

class NumbersHolder {
  dom;
  child;

  height;
  heightChild;

  constructor(__dom) {
    this.dom = __dom;
    this.child = GetBy.class('__holder', __dom)[0];
  }

  loop(p) {
    const y = Maths.map(p, 0, 1, 0, this.height - this.heightChild);
    gsap.set(this.child, { y });
  }

  resize() {
    this.height = this.dom.getBoundingClientRect().height;
    this.heightChild = this.child.getBoundingClientRect().height;
  }
}
