import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Maths } from '../_app/cuchillo/utils/Maths';

class ScrollItem__WidgetLogo extends VScroll_Item {
  simbol;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.simbol = GetBy.class('__simbol', __link)[0];
  }

  drawHook() {
    const normalize = this.progress < .5 ? Math.max(0, Maths.normalize(.35, .25, this.progress)) : Math.max(0, Maths.normalize(.8, .9, this.progress));
    this.simbol.style.opacity = normalize;
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('WidgetLogo', ScrollItem__WidgetLogo);
